export const WORDS = [
  'ready',
  'setty',
  'goooo',
  'haman',
  'music',
  'feast',
  'dress',
  'gifts',
  'malka',
  'mazki',
  'foods',
  'torah',
  'dance',
  'songs',
  'fasts',
  'gladi',
  'purim',
]
